import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetError } from "../reducers/appReducer";

/**
 * Reset error on page load or (optionally) according to the provided dependencies.
 */
export default function useResetError(errorKey, deps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetError(errorKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
}
