import {
  Button,
  Card,
  CardFooter,
  Center,
  H3,
  HStack,
  Input,
  P2,
  Section,
  SmallText,
  Spacer,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import { goToSignupDetails } from "components/ChooseSignupMethod";
import TitleDivider from "components/TitleDivider";
import { useFormik } from "formik";
import loggedOutPage from "hoc/loggedOutPage";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { MailbrewLogo } from "../components/brand/MailbrewLogo";
import Page from "../components/Page";
import StyledLink from "../components/StyledLink";
import useResetError from "../hooks/useResetError";
import { appErrorSelector, appLoadingSelector } from "../reducers/appReducer";
import { login } from "../reducers/authReducer";
import urls from "../urls";
import { signupWithTwitter } from "../utils/twitterAuth";

const LoginPage = () => {
  const dispatch = useDispatch();
  const config = useConfig();
  const loading = useSelector(appLoadingSelector);
  const loginError = useSelector(appErrorSelector("login"));
  const router = useRouter();

  useResetError("login");

  const redirectAfterLoginPath = router.query.next;

  const handleLoginWithTwitter = () => {
    signupWithTwitter((twitterSignupId) => {
      goToSignupDetails({ twitterSignupId });
    });
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object().shape({
      email: yup.string().email("Not a valid email").required("Please type your email"), // prettier-ignore
      password: yup.string().required("Please type your password"),
    }),
    onSubmit: (values) => {
      dispatch(login(values.email, values.password, redirectAfterLoginPath));
    },
  });

  return (
    <Page title="Login" noLogin>
      <Section center width={110}>
        <Center>
          <MailbrewLogo label={false} size="3em" color={config.colors.c3} />
          <Spacer size="xxs" />
          <H3 align="center" color={config.colors.c3}>
            Login to Mailbrew
          </H3>
          <Spacer size="xl" />
        </Center>
        <Card inline width="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack align="stretch" my={1} gap={2}>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
              />
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
              />
              {loginError && <SmallText color={config.Input.colorError}>{loginError}</SmallText>}
            </VStack>
            <CardFooter inline>
              <HStack align="spaced" noWrap>
                <StyledLink variant="link" to={urls.askResetPasswordLink()} color={config.colors.c3}>
                  Forgot password?
                </StyledLink>
                <Button width="8em" disabled={loading} loading={loading} submit>
                  Login
                </Button>
              </HStack>
            </CardFooter>
          </form>
        </Card>
        <Center>
          <TitleDivider title="or" />
          <Button width="100%" icon="twitter" color="rgba(29,161,242,1.00)" onClick={handleLoginWithTwitter}>
            Login with Twitter
          </Button>
        </Center>
        <Spacer size="m" />
        <Center>
          <P2>
            New user?{" "}
            <StyledLink variant="link" to={urls.signup()}>
              Sign up here.
            </StyledLink>
          </P2>
        </Center>
      </Section>
    </Page>
  );
};

export default loggedOutPage(LoginPage);
