import {
  Box,
  Button,
  Card,
  Grid,
  H1,
  H3,
  H5,
  HStack,
  MediaQuery,
  P1,
  P2,
  Section,
  Spacer,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import { MailbrewLogo } from "components/brand/MailbrewLogo";
import TitleDivider from "components/TitleDivider";
import useResetError from "hooks/useResetError";
import heroImage from "images/illustrations/hero.png";
import Router, { useRouter } from "next/router";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { appErrorSelector } from "reducers/appReducer";
import urls from "urls";
import { signupWithTwitter } from "utils/twitterAuth";
import { SignupCouponBadge } from "./SignupForm";
import StyledLink from "./StyledLink";

const ChooseSignupMethod = () => {
  const config = useConfig();

  const twitterSignupError = useSelector(appErrorSelector("signup:twitter"));
  useResetError("signup:twitter");

  const router = useRouter();
  const coupon = router.query.coupon;

  const handleSignupWithTwitter = () => {
    signupWithTwitter((twitterSignupId) => goToSignupDetails({ twitterSignupId }));
  };

  const handleSignupWithEmail = () => {
    goToSignupDetails();
  };

  return (
    <>
      <Spacer />
      <Section center>
        <Grid w={220} maxW="100%" columns="1fr 26em" vAlign="center" gap={4} breakAt={768}>
          <VStack align="center" vAlign="center" centerAt={860} mb={2}>
            <img style={{ width: "17em", maxWidth: "70%" }} src={heroImage} alt="Mailbrew" />
            <MediaQuery hideBelow="860px">
              <H1 align="center" style={{ marginTop: 0 }}>
                Start brewing.
              </H1>
            </MediaQuery>
            <H5 align="center" maxW={"14em"}>
              Try Mailbrew for 2 weeks without limitations!
            </H5>
          </VStack>
          <Box maxWidth={100} margin="0 auto">
            <SignupCouponBadge coupon={coupon} />
            {coupon && <Spacer />}
            <Card center width="100%">
              <HStack align="center" mb={4}>
                <MailbrewLogo label={false} size="2.2em" color={config.colors.c1} />
                <H3>Sign up now</H3>
              </HStack>
              <Button width="100%" icon="twitter" color="rgba(29,161,242,1.00)" onClick={handleSignupWithTwitter}>
                Sign Up with Twitter
              </Button>
              {twitterSignupError ? (
                <P2 color={config.colors.error} align="center">
                  {twitterSignupError}
                </P2>
              ) : (
                <P1 style={{ margin: ".7em 0 0" }} align="center">
                  If you use Twitter, you'll be able to setup a Twitter digest right away.
                </P1>
              )}
              <TitleDivider title="or" small />
              <Button mb={3} variant="white" width="100%" icon="emailBold" onClick={handleSignupWithEmail}>
                Sign Up with Email
              </Button>
              <P2 align="center">
                Already registered?{" "}
                <StyledLink variant="link" to={urls.login()}>
                  Log in here.
                </StyledLink>
              </P2>
            </Card>
          </Box>
        </Grid>
      </Section>
    </>
  );
};

export function goToSignupDetails(options = {}) {
  const { twitterSignupId } = options;

  let qs = queryString.parse(window.location.search);

  if (twitterSignupId) {
    qs["tid"] = twitterSignupId;
  }

  qs = queryString.stringify(qs);
  if (qs.length > 0) qs = "?" + qs;

  Router.push(urls.signupDetails() + qs);
}

export default ChooseSignupMethod;
