import Router from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import urls from "urls";
import { isClient } from "utils/env";
import { authUserSelector } from "../reducers/authReducer";

/**
 * Can't access this page when logged in.
 */
export default function loggedOutPage(Component) {
  function WrappedComponent(props) {
    const user = useSelector(authUserSelector);

    if (!user) {
      return <Component {...props} />;
    } else {
      if (isClient) Router.push(urls.brews());
      return null;
    }
  }

  return WrappedComponent;
}
